<template>
  <el-dialog
      title="公司续费"
      width="40%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="10">
          <el-form-item label="购买时间" prop="payDate">
            <el-date-picker
                style="width:183px"
                v-model="form.payDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="公司" prop="tenantName">
            <el-input v-model="form.tenantName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="付款人" prop="payEmpId">
            <el-select v-model="form.payEmpId" placeholder="付款人" style="width:183px">
              <el-option
                  v-for="item in payEmpIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="购买天数" prop="addDay">
            <el-input type="number" v-model="form.addDay"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="付款金额" prop="money">
            <el-input type="number" v-model="form.money"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="备注">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="TenantPurchaseCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
import DateUtils from "@/common/utils/date-utils";
export default {
  name: "tenant-purchase",
  data() {
    return {
      form: {
        tenantId: this.tenant.id,
        payDate:DateUtils.dateToYMDString(new Date()),
        createDate:new Date(),
        tenantName: this.tenant.tenantName,
        recorderEmpId: JSON.parse(localStorage.getItem("emp")).id,
        payEmpId: "",
        addDay: "",
        money: "",
        remark: "",
        tenantCrop:this.tenant.tenantCrop,
      },
      payEmpIdArray: [],
      rules: {
        payDate: [
          {required: true, message: '请选择购买时间', trigger: 'change'},
        ],
        tenantName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        payEmpId: [
          {required: true, message: '请输入付款人', trigger: 'change'},
        ],
        addDay: [
          {required: true, message: '选择购买天数', trigger: 'blur'},
        ],
        money: [
          {required: true, message: '请输入等级', trigger: 'blur'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    tenant: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryPayEmpIds();
  },
  methods: {
    TenantPurchaseCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.tenantPurchase();
        }
      })
    },
    //添加用户
    tenantPurchase: function () {
      console.log(this.form)
      let value = "是否充值:" + this.form.tenantName
      this.$confirm(value, '充值:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/tenantPurchase/savePurchase",
          data: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("充值成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    queryPayEmpIds(){
      this.$selectUtils.queryEmpIds().then(response=>{
        this.payEmpIdArray=JSON.parse(response.data.data);
      })
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>