<template>
  <el-dialog
      title="公司续费"
      width="70%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <el-table
          :data="table.tenantList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          :stripe="true"
          border
          height="650"
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="tenantName" label="公司名称" align="center"></el-table-column>
        <el-table-column prop="operationDate" label="动账时间" align="center"></el-table-column>
        <el-table-column prop="operationEmpName" label="缴费人" align="center"></el-table-column>
        <el-table-column  label="操作类型" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.operationType === 1" style="color: red">
              存入
            </span>
            <span v-if="scope.row.operationType === 2" style="color: green">
              支出
            </span>
            <span v-if="scope.row.operationType === 3" style="color: red">
              退款
            </span>
          </template>
        </el-table-column>
        <el-table-column  label="金额类型" align="center" >
          <template slot-scope="scope">
            <span>
              {{scope.row.moneyType === 1 ? "押金" : "预付款" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="动账金额" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.operationType === 1" style="color: red">
              + {{scope.row.money}}
            </span>
            <span v-if="scope.row.operationType === 2" style="color: green">
              - {{scope.row.money}}
            </span>
            <span v-if="scope.row.operationType === 3" style="color: red">
              + {{scope.row.money}}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200"  label="账户变动后余额" align="center" >
          <template slot-scope="scope">
            当次{{scope.row.moneyType === 1 ? "押金" : "预付款" }}余额 {{scope.row.balance}}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>

export default {
  name: "tenant-bill-detail",
  data() {
    return {
      table: {
        tenantList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialogState: this.state,
      tenantCrop: localStorage.getItem("tenantCrop"),
    }
  },
  props: {
    tenant: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryTenantBillList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryTenantBillList();
    },
    //查询客户列表
    queryTenantBillList: function () {
      this.$axios({
        method: "GET",
        url: "/tenantBill/queryList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenant.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.tenantList = response.data.data.list;
      })
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryTenantBillList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryTenantBillList()
    },
    close: function () {
      this.$emit("close", false);
    },
  },
}
</script>

<style scoped>

</style>