<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="tenantName" placeholder="公司名称"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="tenantPhone" placeholder="公司联系方式"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="tenantGrade" placeholder="产品等级" clearable>
            <el-option
                v-for="item in gradeList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="isActive" placeholder="活跃状态" clearable>
            <el-option label="活跃" :value="1"/>
            <el-option label="终止" :value="0"/>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <el-table
          :data="table.tenantList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          border
          height="650"
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="tenantName" label="公司名称" align="center"></el-table-column>
        <el-table-column prop="tenantCity" label="所在城市" align="center"></el-table-column>
        <el-table-column prop="tenantCorporation" label="公司联系人" align="center"></el-table-column>
        <el-table-column prop="tenantPhone" label="公司联系方式" align="center"></el-table-column>
        <el-table-column prop="cashPledge" label="押金余额" align="center"></el-table-column>
        <el-table-column prop="advanceCharge" label="订货款余额" align="center"></el-table-column>
        <el-table-column prop="account" label="开通账户数" align="center"></el-table-column>
        <el-table-column prop="endDate" label="到期时间" align="center"></el-table-column>
        <el-table-column  label="活跃状态" align="center" >
          <template slot-scope="scope">
            <span>
              {{scope.row.isActive === 1 ? "活跃" : "终止" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="gradeName" label="产品等级" align="center"></el-table-column>
        <el-table-column width="300" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="success" size="mini" @click="openPurchaseState(scope.row)">续费</el-button>
              <el-button type="info" size="mini" @click="openRecharge(scope.row)">充值</el-button>
              <el-button type="info" size="mini" @click="openBillDetails(scope.row)">明细</el-button>
              <el-button type="warning" size="mini" @click="openPermission(scope.row)">分配PC端权限</el-button>
              <el-button type="danger" size="mini" @click="openMobilePermission(scope.row)">分配移动端权限</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <tenantEdit v-if="dialog.editTenantState" :tenant="dialog.tenant" :state="dialog.editTenantState" @close="close"/>
    <tenantPurchase v-if="dialog.purchaseState" :tenant="dialog.tenant" :state="dialog.purchaseState" @close="close"/>
    <tenantRecharge v-if="dialog.rechargeState" :tenant="dialog.tenant" :state="dialog.rechargeState" @close="close"/>
    <tenantBillDetails v-if="dialog.billDetailsState" :tenant="dialog.tenant" :state="dialog.billDetailsState" @close="close"/>
    <tenantPermission v-if="dialog.permissionState" :tenantInfo="dialog.tenant" :state="dialog.permissionState" @close="close"/>
    <tenantMobilePermission v-if="dialog.mobilePermissionState" :tenantInfo="dialog.tenant" :state="dialog.mobilePermissionState" @close="close"/>
  </div>
</template>

<script>
import tenantEdit from "@/pages/tenant/tenant-list/tenant-edit";
import tenantPurchase from "@/pages/tenant/tenant-list/tenant-purchase";
import tenantRecharge from "@/pages/tenant/tenant-list/tenant-recharge";
import tenantBillDetails from "@/pages/tenant/tenant-list/tenant-bill-details";
import tenantPermission from "@/pages/tenant/tenant-list/tenant-permission";
import tenantMobilePermission from "@/pages/tenant/tenant-list/tenant-mobile-permission";

export default {
  name: "tenant-list",
  data() {
    return {
      tenantName: "",
      tenantGrade: "",
      tenantPhone: "",
      isActive: 1,
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        tenantList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        editTenantState: false,
        purchaseState: false,
        rechargeState: false,
        billDetailsState: false,
        permissionState: false,
        mobilePermissionState: false,
        tenant: {},
      },
      gradeList:[]

    }
  },
  components: {
    tenantEdit,tenantPurchase,tenantRecharge,tenantBillDetails,tenantPermission,tenantMobilePermission
  },
  created() {
    this.queryTenantList();
    this.queryProductGradeIds()
  },
  methods: {
    queryProductGradeIds() {
      this.$selectUtils.queryProductGradeIds().then(response => {
        this.gradeList = response.data.data
      })
    },
    search() {
      this.table.page = 1;
      this.queryTenantList();
    },
    //查询客户列表
    queryTenantList: function () {
      this.$axios({
        method: "GET",
        url: "/tenant/queryTenantList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantName: this.tenantName,
          tenantGrade: this.tenantGrade,
          tenantPhone: this.tenantPhone,
          isActive: this.isActive,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.tenantList = response.data.data.list;
      })
    },
    //打开编辑界面
    openEdit(data) {
      this.dialog.editTenantState = true
      this.dialog.tenant = data
    },
    //打开续费界面
    openPurchaseState(data) {
      this.dialog.purchaseState = true
      this.dialog.tenant = data
    },
    //打开充值页面
    openRecharge(data){
      console.log(data)
      this.dialog.rechargeState = true
      this.dialog.tenant = data
    },
    //打开明细页面
    openBillDetails(data){
      this.dialog.billDetailsState= true
      this.dialog.tenant = data
    },
    //打开分配电脑端视图
    openPermission(data){
      this.dialog.permissionState = true
      this.dialog.tenant = data
    },
    //打开分配手机端视图
    openMobilePermission(data){
      this.dialog.mobilePermissionState = true
      this.dialog.tenant = data
    },

    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryTenantList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryTenantList()
    },
    close:function (state){
      this.dialog.editTenantState=state;
      this.dialog.purchaseState=state;
      this.dialog.rechargeState=state;
      this.dialog.billDetailsState=state;
      this.dialog.permissionState=state;
      this.dialog.mobilePermissionState=state;
      this.queryTenantList();
    },
    grade(val) {
      switch (val) {
        case 1:
          return "基础版"
        case 2:
          return "经销商版"
        case 5:
          return "管理员"
      }
    },
    getColor(val) {
      if (val === 0) {
        return "#f56c6c"
      }
    }
  },
}
</script>

<style scoped>

</style>
